<template>
    <!-- 列表页 -->
    <div class="vyListp">
        <div class="vlpBox">
            <!-- 头部区域 -->
            <div class="vlpTops">
                <div class="vt1">
                    <div class="vtOne">商品分类</div>
                    <div class="vt1Two">
                        <ul class="vt1Ul">
                            <li class="vtLi1">全部</li>
                            <li class="vtLi1">网站建设</li>
                            <li class="vtLi1">企业应用</li>
                            <li class="vtLi1">基础软件</li>
                            <li class="vtLi1">API</li>
                            <li class="vtLi1">服务</li>
                            <li class="vtLi1">安全</li>
                            <li class="vtLi1">开发者工具</li>
                        </ul>
                        <ul class="vt1Ul">
                            <li class="vtLi2">全部</li>
                            <li class="vtLi2">企业官网</li>
                            <li class="vtLi2">电商网站</li>
                            <li class="vtLi2">网站模板</li>
                            <li class="vtLi2">网站定制</li>
                            <li class="vtLi2">网站服务</li>
                            <li class="vtLi2">APP开发</li>
                        </ul>
                    </div>
                </div>
                <div class="vt2">
                    <div class="vtOne">支付方式</div>
                    <div class="vt2Two">
                        <ul>
                            <li class="vtLi1">全部</li>
                            <li class="vtLi1">镜像</li>
                            <li class="vtLi1">Saas</li>
                            <li class="vtLi1">人工服务</li>
                            <li class="vtLi1">API</li>
                            <li class="vtLi1">兑换码</li>
                        </ul>
                    </div>
                </div>
                <div class="vt2">
                    <div class="vtOne">价格</div>
                    <div class="vt2Two">
                        <ul>
                            <li class="vtLi1">全部</li>
                            <li class="vtLi1">免费/试用</li>
                            <li class="vtLi1">1-198</li>
                            <li class="vtLi1">199-1998</li>
                            <li class="vtLi1">1999-2998</li>
                            <li class="vtLi1">2998以上</li>
                            <li class="vtLi1s">
                                <input type="text" />
                                <p>至</p>
                                <input type="text" /><button>确定</button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="vt2">
                    <div class="vtOne">售卖类型</div>
                    <div class="vt2Two">
                        <ul>
                            <li class="vtLi1">全部</li>
                            <li class="vtLi1">甄选商品</li>
                            <li class="vtLi1">官方商品</li>
                            <li class="vtLi1">V+商品</li>
                            <li class="vtLi1">威有云认证</li>
                            <li class="vtLi1">渠道推荐</li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 列表区域 -->
            <div class="vlpLists">
                <!-- 左边主体 -->
                <div class="vlLeft">
                    <!-- 商品列表 -->
                    <div class="vllBox">
                        <!-- 头部 -->
                        <div class="vbTop">
                            <div class="vbt1">
                                <ul>
                                    <li class="vbt1Li">综合排序</li>
                                    <li class="vbt1Li">
                                        使用量<img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-cpmplex.svg"
                                            alt=""
                                            class="vlImg"
                                        />
                                    </li>
                                    <li class="vbt1Li">
                                        上架时间<img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-cpmplex.svg"
                                            alt=""
                                            class="vlImg"
                                        />
                                    </li>
                                    <li class="vbt1Li">
                                        价格
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-cpmplex.svg"
                                            alt=""
                                            class="vlImg"
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div class="vbt2">
                                <div class="vbtNums">共3539件</div>
                                <div class="vbtMores">
                                    <img
                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-square-actived.svg"
                                        alt=""
                                        class="vm1"
                                    />
                                    <img
                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-list.svg"
                                        alt=""
                                        class="vm1"
                                    />
                                </div>
                            </div>
                        </div>
                        <!-- 内容列表 -->
                        <div class="vbLists">
                            <ul>
                                <li class="vblLi" v-for="item in 15" :key="item" @click="goDetail">
                                    <div class="vblImgt">
                                        <img src="http://iv.okvu.cn/vugw/img/test5.png" alt="" class="vblImg" />
                                        <img
                                            src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/2021426-tag-0.svg"
                                            alt=""
                                            class="vblIcon"
                                        />
                                    </div>
                                    <div class="vblData">
                                        <p class="vd1">企业官网智能建站（免费送推广）</p>
                                        <div class="vd2">
                                            <p class="vd2P">广东耐思慧科技有限公司</p>
                                            <div class="vd2B">SaaS支付</div>
                                        </div>
                                        <div class="vd3">
                                            <div class="vd3Xs">
                                                <img
                                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                                    alt=""
                                                    class="vd3X1"
                                                />
                                                <img
                                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                                    alt=""
                                                    class="vd3X1"
                                                />
                                                <img
                                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                                    alt=""
                                                    class="vd3X1"
                                                />
                                                <img
                                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-actived.svg"
                                                    alt=""
                                                    class="vd3X1"
                                                />
                                                <img
                                                    src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/market-place-workflow/build/cloud/images/icon-star-empty.svg"
                                                    alt=""
                                                    class="vd3X2"
                                                />
                                                <p class="vd3X3">（20）</p>
                                            </div>
                                            <p class="vd3P">827人在用</p>
                                        </div>
                                        <div class="vd4">
                                            <p class="vd4P1">威有870</p>
                                            <p class="vd4P2">起威有1200</p>
                                            <div class="vd4B">免费试用</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <!-- 页码跳转 -->
                        <div class="vblPage">
                            <el-pagination background layout="prev, pager, next" :total="2360"> </el-pagination>
                        </div>
                    </div>
                    <!-- 猜你需要 -->
                    <div class="vllGuess">
                        <div class="vgBox">
                            <div class="vgbP">猜你需要</div>
                            <div class="vgbList">
                                <ul>
                                    <li class="vgbLi" v-for="item in 8" :key="item">
                                        <div class="vgImgt">
                                            <img src="http://iv.okvu.cn/vugw/img/test7.png" alt="" class="vgImg" />
                                        </div>
                                        <p class="vgP1">NFT数字藏品系统搭建APP...</p>
                                        <p class="vgP2">0人在用</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 右边区域 -->
                <div class="vlRight">
                    <div class="vlrBox">
                        <div class="vlrP">热门推荐</div>
                        <ul>
                            <li class="vlrLi" v-for="item in 5" :key="item">
                                <div class="vlrImgt">
                                    <img src="http://iv.okvu.cn/vugw/img/test6.png" alt="" class="vlrImg" />
                                    <img
                                        src="https://imgcache.qq.com/open_proj/proj_qcloud_v2/marketPlace/build/images/2021426-tag-0.svg"
                                        alt=""
                                        class="vlrIcon"
                                    />
                                </div>
                                <p class="vlrP">云-H5响应式模板建站（送推广）</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VyListp",
    methods: {
        goDetail() {
            this.$router.push("/vyDetail");
        },
    },
};
</script>

<style lang="less" scoped>
.vyListp {
    width: 100%;
    margin-top: 120px;
    .vlpBox {
        width: 1138px;
        height: 100%;
        margin: 0 auto;
        // 头部区域
        .vlpTops {
            width: 100%;
            height: 240px;
            background-color: white;
            box-shadow: 1px 1px 10px 1px gainsboro;
            border-radius: 20px;
            .vt1 {
                width: 95%;
                height: 90px;
                margin: 0 auto;
                border-bottom: 1px solid gainsboro;
                .vtOne {
                    width: 10%;
                    height: 90px;
                    float: left;
                    color: gray;
                    padding-top: 10px;
                    box-sizing: border-box;
                }
                .vt1Two {
                    width: 90%;
                    height: 90px;
                    float: left;
                    .vt1Ul {
                        width: 100%;
                        height: 50%;
                        display: flex;
                        .vtLi1 {
                            max-width: 92px;
                            height: 30px;
                            line-height: 30px;
                            margin-left: 30px;
                            font-size: 14px;
                            padding-top: 10px;
                            cursor: pointer;
                        }
                        .vtLi1:nth-child(2) {
                            color: #006eff;
                        }
                        .vtLi1:hover {
                            color: #006eff;
                        }
                        .vtLi2 {
                            min-width: 60px;
                            height: 26px;
                            line-height: 26px;
                            margin-left: 10px;
                            text-align: center;
                            font-size: 12px;
                            margin-top: 10px;
                            cursor: pointer;
                            background-color: #f2f7fc;
                        }
                        .vtLi2:nth-child(1) {
                            background-color: #dbebff;
                            color: #006eff;
                        }
                        .vtLi2:hover {
                            background-color: #dbebff;
                            color: #006eff;
                        }
                    }
                }
            }
            .vt2 {
                width: 95%;
                height: 48px;
                line-height: 48px;
                margin: 0 auto;
                border-bottom: 1px solid gainsboro;
                .vtOne {
                    width: 10%;
                    height: 48px;
                    float: left;
                    color: gray;
                    float: left;
                }
                .vt2Two {
                    width: 90%;
                    height: 48px;
                    float: left;
                    ul {
                        width: 100%;
                        height: 48px;
                        display: flex;
                        .vtLi1 {
                            max-width: 92px;
                            height: 48px;
                            line-height: 48px;
                            margin-left: 30px;
                            font-size: 14px;
                            cursor: pointer;
                        }
                        .vtLi1:nth-child(1) {
                            color: #006eff;
                        }
                        .vtLi1:hover {
                            color: #006eff;
                        }
                        .vtLi1s {
                            width: 250px;
                            height: 25px;
                            line-height: 25px;
                            margin-left: 30px;
                            margin-top: 10px;
                            input {
                                width: 65px;
                                height: 20px;
                                float: left;
                            }
                            p {
                                width: 30px;
                                height: 25px;
                                text-align: center;
                                float: left;
                            }
                            button {
                                width: 58px;
                                height: 25px;
                                float: left;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
            .vt2:nth-child(4) {
                border: none;
            }
        }
        // 列表区域
        .vlpLists {
            width: 100%;
            height: 2600px;
            margin-top: 20px;
            // 左边主体
            .vlLeft {
                width: 81%;
                height: 100%;
                float: left;
                // 商品列表
                .vllBox {
                    width: 100%;
                    height: 1950px;
                    margin: 0 auto;
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    // 头部
                    .vbTop {
                        width: 100%;
                        height: 50px;
                        border-bottom: #eee;
                        .vbt1 {
                            width: 300px;
                            height: 50px;
                            float: left;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-around;
                                .vbt1Li {
                                    line-height: 50px;
                                    font-size: 12px;
                                    cursor: pointer;
                                    .vlImg {
                                        padding-left: 5px;
                                    }
                                }
                                .vbt1Li:nth-child(1) {
                                    color: #00a4ff;
                                }
                                .vbt1Li:hover {
                                    color: #00a4ff;
                                }
                            }
                        }
                        .vbt2 {
                            width: 170px;
                            height: 50px;
                            float: right;
                            .vbtNums {
                                width: 70px;
                                height: 20px;
                                line-height: 20px;
                                font-size: 12px;
                                float: left;
                                margin-top: 15px;
                                border-right: 1px solid gray;
                            }
                            .vbtMores {
                                width: 90px;
                                height: 30px;
                                line-height: 30px;
                                margin-top: 15px;
                                float: left;
                                .vm1 {
                                    width: 20px;
                                    margin-left: 20px;
                                    float: left;
                                }
                            }
                        }
                    }
                    // 内容列表
                    .vbLists {
                        width: 100%;
                        height: 1800px;
                        margin-top: 20px;
                        ul {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-around;
                            .vblLi {
                                width: 32%;
                                height: 340px;
                                background-color: #fff;
                                border-radius: 10px;
                                box-shadow: 1px 1px 10px 1px gainsboro;
                                .vblImgt {
                                    width: 100%;
                                    height: 60%;
                                    position: relative;
                                    overflow: hidden;
                                    border-top-left-radius: 10px;
                                    border-top-right-radius: 10px;
                                    .vblImg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                    .vblIcon {
                                        position: absolute;
                                        top: 20px;
                                        right: 10px;
                                    }
                                    .vblImg:hover {
                                        transform: scale(1.05);
                                        transition: 0.5s;
                                    }
                                }
                                .vblData {
                                    width: 90%;
                                    height: 40%;
                                    margin: 0 auto;
                                    .vd1 {
                                        width: 100%;
                                        height: 20px;
                                        line-height: 20px;
                                        font-size: 14px;
                                        margin-top: 10px;
                                        cursor: pointer;
                                    }
                                    .vd1:hover {
                                        color: #006eff;
                                    }
                                    .vd2 {
                                        width: 100%;
                                        height: 18px;
                                        line-height: 18px;
                                        font-size: 12px;
                                        margin-top: 10px;
                                        .vd2P {
                                            width: 150px;
                                            float: left;
                                        }
                                        .vd2B {
                                            width: 75px;
                                            float: right;
                                            text-align: center;
                                            border-radius: 20px;
                                            color: #34a4fc;
                                            border: 1px solid #34a4fc;
                                        }
                                    }
                                    .vd3 {
                                        width: 100%;
                                        height: 18px;
                                        line-height: 18px;
                                        margin-top: 10px;
                                        .vd3Xs {
                                            width: 100px;
                                            float: left;
                                            .vd3X1 {
                                                width: 14px;
                                                height: 14px;
                                                float: left;
                                            }
                                            .vd3X2 {
                                                width: 14px;
                                                height: 14px;
                                                float: left;
                                            }
                                            .vd3X3 {
                                                width: 21px;
                                                float: left;
                                                font-size: 12px;
                                            }
                                        }
                                        .vd3P {
                                            font-size: 12px;
                                            float: right;
                                        }
                                    }
                                    .vd4 {
                                        width: 100%;
                                        height: 35px;
                                        line-height: 35px;
                                        margin-top: 15px;
                                        .vd4P1 {
                                            width: 60px;
                                            font-size: 20px;
                                            color: #f43f2d;
                                            float: left;
                                        }
                                        .vd4P2 {
                                            width: 70px;
                                            font-size: 14px;
                                            float: left;
                                            text-decoration: line-through;
                                        }
                                        .vd4B {
                                            width: 62px;
                                            height: 18px;
                                            line-height: 18px;
                                            text-align: center;
                                            margin-top: 6px;
                                            border-radius: 5px;
                                            color: #f09816;
                                            border: 1px solid #f09816;
                                            float: right;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                            .vblLi:hover {
                                box-shadow: 2px 2px 10px 2px gray;
                                transition: 0.5s;
                            }
                        }
                    }
                    // 页码跳转
                    .vblPage {
                        width: 100%;
                        height: 35px;
                        margin-top: 30px;
                        padding-left: 480px;
                        box-sizing: border-box;
                    }
                }
                // 猜你需要
                .vllGuess {
                    width: 98%;
                    height: 592px;
                    margin-top: 30px;
                    margin-left: 10px;
                    border-radius: 20px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    .vgBox {
                        width: 95%;
                        height: 100%;
                        margin: 0 auto;
                        .vgbP {
                            width: 100%;
                            height: 25px;
                            line-height: 25px;
                            font-size: 24px;
                            padding-top: 20px;
                        }
                        .vgbList {
                            width: 100%;
                            height: 520px;
                            margin-top: 25px;
                            ul {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-around;
                                .vgbLi {
                                    width: 23%;
                                    height: 240px;
                                    border-radius: 10px;
                                    box-shadow: 1px 1px 10px 1px gainsboro;
                                    .vgImgt {
                                        width: 100%;
                                        height: 60%;
                                        overflow: hidden;
                                        border-top-left-radius: 10px;
                                        border-top-right-radius: 10px;
                                        .vgImg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                        .vgImg:hover {
                                            transform: scale(1.05);
                                            transition: 0.5s;
                                        }
                                    }
                                    .vgP1 {
                                        width: 100%;
                                        height: 20px;
                                        line-height: 20px;
                                        font-size: 14px;
                                        margin-top: 10px;
                                        text-align: center;
                                    }
                                    .vgP2 {
                                        width: 100%;
                                        height: 12px;
                                        line-height: 12px;
                                        font-size: 12px;
                                        margin-top: 40px;
                                        padding-left: 145px;
                                        box-sizing: border-box;
                                        color: #e14d42;
                                    }
                                }
                                .vgbLi:hover {
                                    box-shadow: 2px 2px 10px 2px gray;
                                    transition: 0.5s;
                                }
                            }
                        }
                    }
                }
            }
            //  右边区域
            .vlRight {
                width: 18%;
                height: 940px;
                float: right;
                border-radius: 10px;
                box-shadow: 1px 1px 10px 1px gainsboro;
                .vlrBox {
                    width: 90%;
                    height: 90%;
                    margin: 0 auto;
                    margin-top: 25px;
                    .vlrP {
                        width: 100%;
                        height: 24px;
                        line-height: 24px;
                    }
                    ul {
                        width: 100%;
                        height: 100%;
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        .vlrLi {
                            width: 100%;
                            height: 157px;
                            .vlrImgt {
                                width: 100%;
                                height: 125px;
                                border-radius: 10px;
                                position: relative;
                                overflow: hidden;
                                .vlrImg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 10px;
                                }
                                .vlrIcon {
                                    position: absolute;
                                    top: 10px;
                                    right: 10px;
                                }
                                .vlrImg:hover {
                                    transform: scale(1.05);
                                    transition: 0.5s;
                                }
                            }
                            .vlrP {
                                width: 100%;
                                height: 20px;
                                text-align: center;
                                line-height: 20px;
                                font-size: 12px;
                                margin-top: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
